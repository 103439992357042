


@media(max-width:768px){

    .new{
        font-size: 16px!important;
       
        text-align: left!important;
        font-weight: 600!important;
    }

    .wid{
        width: 33%!important;
    }

    #bt{
        display: none!important;
    }
    
}



.new{
    margin: 0!important;
}

.thumbnail::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.75rem;
    margin-left: -1.75rem;
    z-index: 1000;
    width: 3.5rem;
    height: 3.5rem;
    background-position: center;
    background-size: cover;
}