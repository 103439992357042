
ul {
    list-style: none;
    word-wrap: break-word;
  }
  
  /* Pages */
  
  .pages {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .page {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  
  /* Login Page */
  
  .login.page {
    background-color: #3777a0
  }
  
  .login.page .form {
    height: 100px;
    margin-top: -100px;
    position: absolute;
  
    text-align: center;
    top: 50%;
    width: 100%;
  }
  
  .login.page .form .usernameInput {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    outline: none;
    padding-bottom: 15px;
    text-align: center;
    width: 100%
  }
  
  .login.page .title {
    font-size: 165%;
  }
  
  .login.page .usernameInput {
    font-size: 200%;
    letter-spacing: 3px;
  }
  
  .login.page .title, .login.page .usernameInput {
    color: #fff;
    font-weight: 100;
  }
  
  /* Chat page */
  
  .chat.page {
    display: none;
  }
  
  /* Font */
  
  .messages {
    font-size: 16px;
  }
  
  .inputMessage {
    font-size: 100%;
  }
  
  .log {
    color: gray;
    font-size: 16px;
    margin: 5px;
    text-align: center;
  }
  
  /* Messages */
  
  .chatArea {
    height: 100%;
    padding-bottom: 60px;
  }
  
  .messages {
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    padding: 10px 20px 10px 20px;
  }
  
  .message.typing .messageBody {
    color: gray;
  }
  
  .username {
    font-weight: 700;
    overflow: hidden;
    padding-right: 15px;
    text-align: right;
  }
  
  /* Input */
  
  .inputMessage {
    border: 4px solid #dcdcdc;
    bottom: 0;
    height: 54px;
    left: 0;
    outline: none;
    padding-left: 10px;
    position: absolute;
    right: 0;
    width: 80%;
  }