

@media(max-width:1023px){
    .sidebar  {
display: none!important;
}}


.log {
    color: rgb(85 144 194)!important;

}