.tg-list {
    text-align: center;
    display: flex;
    align-items: center;
}
.tg-list-item {
  
    list-style: none;
    padding: 16px;
}

.tgl {
    display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
    box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
    background: none;
}
.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.tgl + .tgl-btn:after {
    left: 0;
}
.tgl + .tgl-btn:before {
    display: none;
}
.tgl:checked + .tgl-btn:after {
    left: 50%;
}

.tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: #888;
}
.tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
}
.tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
    background: #484848;
}
.tgl-skewed + .tgl-btn:active:before {
    left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
    background: #fc4a1a;
}
.tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
    left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
}