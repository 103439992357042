.bg {
    background-image: url(../../../images/bg.jpg);
  }


  .bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;   
    background-size: cover;
   
  }




  .new{background: white;
    color: #f44336;
    max-width: 509px;
    display: flex;
    margin: 0 auto 30px;
    justify-content: left;
    align-items: center;
    padding: 12px;
    border-radius: 4px;}