


@media(max-width:768px){

    .box_new__x14_b{
        font-size: 16px!important;
       
        text-align: left!important;
        font-weight: 600!important;
    }

    .box_wid__a8rwI{
        width: 33%!important;
    }

    #box_bt__3qfwF{
        display: none!important;
    }
    
}



.box_new__x14_b{
    margin: 0!important;
}

.box_thumbnail__vcvfq::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.75rem;
    margin-left: -1.75rem;
    z-index: 1000;
    width: 3.5rem;
    height: 3.5rem;
    background-position: center;
    background-size: cover;
}
.modal_bg__1XHgm {
    background-image: url(/static/media/bg.0e97bde0.jpg);
  }


  .modal_bg__1XHgm {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;   
    background-size: cover;
   
  }




  .modal_new__27ID3{background: white;
    color: #f44336;
    max-width: 509px;
    display: flex;
    margin: 0 auto 30px;
    justify-content: left;
    align-items: center;
    padding: 12px;
    border-radius: 4px;}


@media(max-width:1023px){
    .section_sidebar__3YX16  {
display: none!important;
}}


.section_log__2yx8z {
    color: rgb(85 144 194)!important;

}

    .nav_sidebar1__1l5Lx  {
display: none!important;
    }


@media(max-width:1023px){
    .nav_sidebar1__1l5Lx  {
display: block!important;
}}
body {
    font-family: sans-serif;
   
}
a {
    color: #0ebeff;
}
a:not(:hover) {
    text-decoration: none;
}
.site {
    min-height: 100vh;
}
.f {
    display: flex;
}
.jcc {
    justify-content: center;
}

.custom{    background: white;
    padding: 11px 25px;
    margin: 50px auto 50px;
    text-align: center;min-height: 4em}
.aic {
    align-items: center;
    width: 85%;
    margin: 20px auto;
}
.mha {
    margin: 0 auto;
}
.px2 {
    padding: 2rem;
}
.step__wrapper {
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(20px);
    opacity: 0;
}
.step__wrapper.is-active {
    transform: translateY(0px);
    opacity: 1;
}
.button {
 
    border: 1px solid black;
    padding: 1.1rem 2rem 1rem;
    display: inline-block;
    cursor: pointer;
    margin-top: 20px;
}
.button:hover {
    background-color: black;
    color: white;
}
.container--q {
    max-width: 400px;
}

.quiz__options{margin: 0 auto 12px;}
.quiz__options > div {
    transition: all 0.25s;
    padding: 1rem 1.3rem;
    cursor: pointer;
    border: 2px solid gray;
    margin: 4px;
}
.quiz__options > div:hover {
    background-color: gray;
    color: white;
}
.quiz__options > div.cg {
    background-color: green;
    color: white;
    border-color: green;
}
.quiz__options > div.cr {
    background-color: red;
    color: white;
    border-color: red;
}
.quiz__options > div.cw {
    opacity: 0.5;
}


@media(max-width:768px){

.quiz__options{
    width: 90%!important;
    margin: 0 auto 12px;
}

}

ul {
    list-style: none;
    word-wrap: break-word;
  }
  
  /* Pages */
  
  .pages {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .page {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  
  /* Login Page */
  
  .login.page {
    background-color: #3777a0
  }
  
  .login.page .form {
    height: 100px;
    margin-top: -100px;
    position: absolute;
  
    text-align: center;
    top: 50%;
    width: 100%;
  }
  
  .login.page .form .usernameInput {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    outline: none;
    padding-bottom: 15px;
    text-align: center;
    width: 100%
  }
  
  .login.page .title {
    font-size: 165%;
  }
  
  .login.page .usernameInput {
    font-size: 200%;
    letter-spacing: 3px;
  }
  
  .login.page .title, .login.page .usernameInput {
    color: #fff;
    font-weight: 100;
  }
  
  /* Chat page */
  
  .chat.page {
    display: none;
  }
  
  /* Font */
  
  .messages {
    font-size: 16px;
  }
  
  .inputMessage {
    font-size: 100%;
  }
  
  .log {
    color: gray;
    font-size: 16px;
    margin: 5px;
    text-align: center;
  }
  
  /* Messages */
  
  .chatArea {
    height: 100%;
    padding-bottom: 60px;
  }
  
  .messages {
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    padding: 10px 20px 10px 20px;
  }
  
  .message.typing .messageBody {
    color: gray;
  }
  
  .username {
    font-weight: 700;
    overflow: hidden;
    padding-right: 15px;
    text-align: right;
  }
  
  /* Input */
  
  .inputMessage {
    border: 4px solid #dcdcdc;
    bottom: 0;
    height: 54px;
    left: 0;
    outline: none;
    padding-left: 10px;
    position: absolute;
    right: 0;
    width: 80%;
  }
.tg-list {
    text-align: center;
    display: flex;
    align-items: center;
}
.tg-list-item {
  
    list-style: none;
    padding: 16px;
}

.tgl {
    display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
    box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
    background: none;
}
.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.tgl + .tgl-btn:after {
    left: 0;
}
.tgl + .tgl-btn:before {
    display: none;
}
.tgl:checked + .tgl-btn:after {
    left: 50%;
}

.tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: #888;
}
.tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
}
.tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
    background: #484848;
}
.tgl-skewed + .tgl-btn:active:before {
    left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
    background: #fc4a1a;
}
.tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
    left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
}
/* html{
    background-color: #ce81ff;
    background-image: -webkit-linear-gradient(90deg, #ce81ff 50%, #e9edff 50%);
    min-height: 789px;
} */

body{
    background-color: #E4E9FD;
	background-image: -webkit-linear-gradient(65deg, #A683E3 50%, #E4E9FD 50%);
	margin: 0;
	overflow-x: hidden;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	line-height: 1em;
}

button {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	cursor: pointer;
}

input {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
}

input[type="submit"] {
	cursor: pointer;
}

input::-webkit-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input:-ms-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input::-ms-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input::placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}



/**
 * Page background
 */




.user_options-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: rgba(34, 34, 34, 0.85);
	border-radius: 3px;
}

/**
 * Registered and Unregistered user box and text
 */
.user_options-registered,
.user_options-unregistered {
	width: 50%;
	padding: 75px 45px;
	color: #fff;
	font-weight: 300;
}

.user_registered-title,
.user_unregistered-title {
	margin-bottom: 15px;
	font-size: 1.66rem;
	line-height: 1em;
}

.user_unregistered-text,
.user_registered-text {
	font-size: .83rem;
	line-height: 1.4em;
}

.loginh > h2{
	font-weight: 700!important;
    color: #01719c;
    font-size: 22px;
	line-height: 1.2;
    letter-spacing: 0.8px;
}

.loginp {
    text-align: center;
    font-size: 16px;
    margin: 7px 0 12px;
	line-height: 1.2;
    letter-spacing: 0.8px;
}

.user_registered-login,
.user_unregistered-signup {
	margin-top: 30px;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 10px 30px;
	color: #fff;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: .2rem;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.user_registered-login:hover,
  .user_unregistered-signup:hover {
	color: rgba(34, 34, 34, 0.85);
	background-color: #ccc;
}

/**
 * Login and signup forms
 */
.user_options-forms {

margin:100px auto;
width: 38%;
	
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25);
	
}

.user_options-forms .user_forms-login {
	transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

.user_options-forms .forms_title {
	margin-bottom: 45px;
	font-size: 29px;
	font-weight: 500;
	line-height: 1em;
	color: #2196f3;
	
}

.user_options-forms .forms_field:not(:last-of-type) {
	margin-bottom: 20px;
}

.user_options-forms .forms_field-input {
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 6px 20px 6px 6px;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-weight: 300;
	color: gray;
	letter-spacing: .1rem;
	transition: border-color .2s ease-in-out;
}

.user_options-forms .forms_field-input:focus {
	border-color: gray;
}

.user_options-forms .forms_buttons {

	justify-content: space-between;
	align-items: center;
	margin-top: 35px;
}

.user_options-forms .forms_buttons-forgot {
	font-family: "Montserrat", sans-serif;
	letter-spacing: .1rem;
	color: #ccc;
	text-decoration: underline;
	transition: color .2s ease-in-out;
}

.user_options-forms .forms_buttons-forgot:hover {
	color: #b3b3b3;
}

.user_options-forms .forms_buttons-action {
	background-color: #2196f3;
	border-radius: 3px;
	padding: 10px 35px;
	font-size: 15px;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .1rem;
	transition: background-color .2s ease-in-out;
}

.user_options-forms .forms_buttons-action:hover {
	background-color:#0084ec;
}




.user_options-forms .user_forms-signup .forms_buttons {
	justify-content: flex-end;
}

.user_options-forms .user_forms-login {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}


/**
 * Responsive 990px
 */
@media screen and (max-width: 990px) {
	.user_options-forms {
		min-height: 350px;
	}

	.user_options-forms .forms_buttons {
		flex-direction: column;
	}

	.user_options-forms .user_forms-login .forms_buttons-action {
		margin-top: 30px;
	}

	.user_options-forms .user_forms-signup,
    .user_options-forms .user_forms-login {
		top: 40px;
	}

	.user_options-registered,
  .user_options-unregistered {
		padding: 50px 45px;
	}
}

@media  (max-width: 768px) {
	.user_options-forms {
        width: 95%;
	}}


	input::-webkit-input-placeholder {
    font-size: 12px;
  
}
/* html{
    background-color: #ce81ff;
    background-image: -webkit-linear-gradient(90deg, #ce81ff 50%, #e9edff 50%);
    min-height: 789px;
} */

body{
    background-color: #E4E9FD;
	background-image: -webkit-linear-gradient(65deg, #A683E3 50%, #E4E9FD 50%);
	margin: 0;
	overflow-x: hidden;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	line-height: 1em;
}

select#standard-select {
    border-bottom: 1px solid #ccc!important;
    border: none;
  }
button {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	cursor: pointer;
}

input {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
}

input[type="submit"] {
	cursor: pointer;
}

input::-webkit-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input:-ms-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input::-ms-input-placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}

input::placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}



/**
 * Page background
 */




.user_options-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: rgba(34, 34, 34, 0.85);
	border-radius: 3px;
}

/**
 * Registered and Unregistered user box and text
 */
.user_options-registered,
.user_options-unregistered {
	width: 50%;
	padding: 75px 45px;
	color: #fff;
	font-weight: 300;
}

.user_registered-title,
.user_unregistered-title {
	margin-bottom: 15px;
	font-size: 1.66rem;
	line-height: 1em;
}

.user_unregistered-text,
.user_registered-text {
	font-size: .83rem;
	line-height: 1.4em;
}

.user_registered-login,
.user_unregistered-signup {
	margin-top: 30px;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 10px 30px;
	color: #fff;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: .2rem;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.user_registered-login:hover,
  .user_unregistered-signup:hover {
	color: rgba(34, 34, 34, 0.85);
	background-color: #ccc;
}

/**
 * Login and signup forms
 */
.user_options-forms {

margin:100px auto;
width: 38%;
	
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25);
	
}

.user_options-forms .user_forms-login {
	transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

.user_options-forms .forms_title {
	margin-bottom: 45px;
	font-size: 29px;
	font-weight: 500;
	line-height: 1em;
	color: #2196f3;
	
}

.user_options-forms .forms_field:not(:last-of-type) {
	margin-bottom: 20px;
}

.user_options-forms .forms_field-input {
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 6px 20px 6px 6px;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-weight: 300;
	color: gray;
	letter-spacing: .1rem;
	transition: border-color .2s ease-in-out;
}

.user_options-forms .forms_field-input:focus {
	border-color: gray;
}

.user_options-forms .forms_buttons {

	justify-content: space-between;
	align-items: center;
	margin-top: 35px;
}

.user_options-forms .forms_buttons-forgot {
	font-family: "Montserrat", sans-serif;
	letter-spacing: .1rem;
	color: #ccc;
	text-decoration: underline;
	transition: color .2s ease-in-out;
}

.user_options-forms .forms_buttons-forgot:hover {
	color: #b3b3b3;
}

.user_options-forms .forms_buttons-action {
	background-color: #2196f3;
	border-radius: 3px;
	padding: 10px 35px;
	font-size: 15px;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .1rem;
	transition: background-color .2s ease-in-out;
}

.user_options-forms .forms_buttons-action:hover {
	background-color:#0084ec;
}




.user_options-forms .user_forms-signup .forms_buttons {
	justify-content: flex-end;
}

.user_options-forms .user_forms-login {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}


/**
 * Responsive 990px
 */
@media screen and (max-width: 990px) {
	.user_options-forms {
		min-height: 350px;
	}

	.user_options-forms .forms_buttons {
		flex-direction: column;
	}

	.user_options-forms .user_forms-login .forms_buttons-action {
		margin-top: 30px;
	}

	.user_options-forms .user_forms-signup,
    .user_options-forms .user_forms-login {
		top: 40px;
	}

	.user_options-registered,
  .user_options-unregistered {
		padding: 50px 45px;
	}
}

@media  (max-width: 768px) {
	.user_options-forms {
        width: 95%;
	}}


	input::-webkit-input-placeholder {
    font-size: 12px;
  
}
