body {
    font-family: sans-serif;
   
}
a {
    color: #0ebeff;
}
a:not(:hover) {
    text-decoration: none;
}
.site {
    min-height: 100vh;
}
.f {
    display: flex;
}
.jcc {
    justify-content: center;
}

.custom{    background: white;
    padding: 11px 25px;
    margin: 50px auto 50px;
    text-align: center;min-height: 4em}
.aic {
    align-items: center;
    width: 85%;
    margin: 20px auto;
}
.mha {
    margin: 0 auto;
}
.px2 {
    padding: 2rem;
}
.step__wrapper {
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(20px);
    opacity: 0;
}
.step__wrapper.is-active {
    transform: translateY(0px);
    opacity: 1;
}
.button {
 
    border: 1px solid black;
    padding: 1.1rem 2rem 1rem;
    display: inline-block;
    cursor: pointer;
    margin-top: 20px;
}
.button:hover {
    background-color: black;
    color: white;
}
.container--q {
    max-width: 400px;
}

.quiz__options{margin: 0 auto 12px;}
.quiz__options > div {
    transition: all 0.25s;
    padding: 1rem 1.3rem;
    cursor: pointer;
    border: 2px solid gray;
    margin: 4px;
}
.quiz__options > div:hover {
    background-color: gray;
    color: white;
}
.quiz__options > div.cg {
    background-color: green;
    color: white;
    border-color: green;
}
.quiz__options > div.cr {
    background-color: red;
    color: white;
    border-color: red;
}
.quiz__options > div.cw {
    opacity: 0.5;
}


@media(max-width:768px){

.quiz__options{
    width: 90%!important;
    margin: 0 auto 12px;
}

}