/* html{
    background-color: #ce81ff;
    background-image: -webkit-linear-gradient(90deg, #ce81ff 50%, #e9edff 50%);
    min-height: 789px;
} */

body{
    background-color: #E4E9FD;
	background-image: -webkit-linear-gradient(65deg, #A683E3 50%, #E4E9FD 50%);
	margin: 0;
	overflow-x: hidden;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	line-height: 1em;
}

button {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
	cursor: pointer;
}

input {
	background-color: transparent;
	padding: 0;
	border: 0;
	outline: 0;
}

input[type="submit"] {
	cursor: pointer;
}

input::placeholder {
	font-size: .85rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	letter-spacing: .1rem;
	color: #ccc;
}



/**
 * Page background
 */




.user_options-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: rgba(34, 34, 34, 0.85);
	border-radius: 3px;
}

/**
 * Registered and Unregistered user box and text
 */
.user_options-registered,
.user_options-unregistered {
	width: 50%;
	padding: 75px 45px;
	color: #fff;
	font-weight: 300;
}

.user_registered-title,
.user_unregistered-title {
	margin-bottom: 15px;
	font-size: 1.66rem;
	line-height: 1em;
}

.user_unregistered-text,
.user_registered-text {
	font-size: .83rem;
	line-height: 1.4em;
}

.loginh > h2{
	font-weight: 700!important;
    color: #01719c;
    font-size: 22px;
	line-height: 1.2;
    letter-spacing: 0.8px;
}

.loginp {
    text-align: center;
    font-size: 16px;
    margin: 7px 0 12px;
	line-height: 1.2;
    letter-spacing: 0.8px;
}

.user_registered-login,
.user_unregistered-signup {
	margin-top: 30px;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 10px 30px;
	color: #fff;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: .2rem;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.user_registered-login:hover,
  .user_unregistered-signup:hover {
	color: rgba(34, 34, 34, 0.85);
	background-color: #ccc;
}

/**
 * Login and signup forms
 */
.user_options-forms {

margin:100px auto;
width: 38%;
	
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25);
	
}

.user_options-forms .user_forms-login {
	transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

.user_options-forms .forms_title {
	margin-bottom: 45px;
	font-size: 29px;
	font-weight: 500;
	line-height: 1em;
	color: #2196f3;
	
}

.user_options-forms .forms_field:not(:last-of-type) {
	margin-bottom: 20px;
}

.user_options-forms .forms_field-input {
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 6px 20px 6px 6px;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-weight: 300;
	color: gray;
	letter-spacing: .1rem;
	transition: border-color .2s ease-in-out;
}

.user_options-forms .forms_field-input:focus {
	border-color: gray;
}

.user_options-forms .forms_buttons {

	justify-content: space-between;
	align-items: center;
	margin-top: 35px;
}

.user_options-forms .forms_buttons-forgot {
	font-family: "Montserrat", sans-serif;
	letter-spacing: .1rem;
	color: #ccc;
	text-decoration: underline;
	transition: color .2s ease-in-out;
}

.user_options-forms .forms_buttons-forgot:hover {
	color: #b3b3b3;
}

.user_options-forms .forms_buttons-action {
	background-color: #2196f3;
	border-radius: 3px;
	padding: 10px 35px;
	font-size: 15px;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .1rem;
	transition: background-color .2s ease-in-out;
}

.user_options-forms .forms_buttons-action:hover {
	background-color:#0084ec;
}




.user_options-forms .user_forms-signup .forms_buttons {
	justify-content: flex-end;
}

.user_options-forms .user_forms-login {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}


/**
 * Responsive 990px
 */
@media screen and (max-width: 990px) {
	.user_options-forms {
		min-height: 350px;
	}

	.user_options-forms .forms_buttons {
		flex-direction: column;
	}

	.user_options-forms .user_forms-login .forms_buttons-action {
		margin-top: 30px;
	}

	.user_options-forms .user_forms-signup,
    .user_options-forms .user_forms-login {
		top: 40px;
	}

	.user_options-registered,
  .user_options-unregistered {
		padding: 50px 45px;
	}
}

@media  (max-width: 768px) {
	.user_options-forms {
        width: 95%;
	}}


	input::-webkit-input-placeholder {
    font-size: 12px;
  
}